<template>
  <div class="market">
    <div class="market-banner">
      <img src="../../assets/img/market-banner.png" class="img" />
      <div>
        <p>工业应用市场</p>
        <p>智能制造一体化、数字化、智能化、服务及产品</p>
        <a-input
          class="market-search"
          style="width: 500px"
          v-model="searchData"
          placeholder="搜索应用名称"
          :maxLength="50"
          @pressEnter="searchChange"
        >
          <template slot="suffix">
            <img
              style="cursor: pointer"
              alt="图片"
              src="@/assets/img/market-search.png"
              @click="searchChange"
            />
          </template>
        </a-input>
      </div>
    </div>
    <div class="market-box">
      <div class="market-con">
        <div class="market-con-tab">
          <div
            v-for="el in tabList"
            :key="el.category_id"
            @click="selectTab(el.category_id)"
            :class="{ 'tab-active': categoryActive === el.category_id }"
          >
            {{ el.name }}
          </div>
        </div>
        <div>
          <div class="market-con-right">
            <a-skeleton avatar :loading="loading" :paragraph="{ rows: 10 }">
              <div class="market-list" v-if="marketList.length > 0">
                <market-card
                  v-for="el in marketList"
                  :val="el"
                  :key="el.id"
                ></market-card>
              </div>
              <div
                v-else
                style="padding: 200px 0; margin: 0 auto; display: block"
              >
                <a-empty />
              </div>
            </a-skeleton>
          </div>
          <pagination
            v-show="marketList.length > 0"
            class="market-page"
            :total="page.total"
            :current="page.current"
            @changes="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import marketCard from "@/page/market/components/marketCard";
import pagination from "../components/pagination.vue";
import { getAppCategories, getAppPage } from "@/api/yuer";
export default {
  name: "index",
  components: {
    marketCard,
    pagination,
  },
  data() {
    return {
      loading: false,
      searchData: "",
      tabList: [],
      marketList: [],
      categoryActive: "",
      name_like: "",
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.loadCategories();
    this.loadData();
  },
  watch: {
    searchData(val) {
      if (!val && this.name_like) {
        this.name_like = "";
        this.page.current = 1;
        this.loadData();
      }
    },
  },
  methods: {
    searchChange() {
      if (this.searchData) {
        this.name_like = this.searchData;
        this.page.current = 1;
        this.loadData();
      }
    },
    selectTab(id) {
      this.categoryActive = id;
      this.loadData();
    },
    loadCategories() {
      let data = {
        name: "全部",
        category_id: "",
      };
      getAppCategories().then((res) => {
        res.unshift(data);
        this.tabList = res;
      });
    },
    loadData() {
      if ((this.marketList = [])) {
        this.loading = true;
      }
      const { current, size } = this.page;
      let pageData = {
        current,
        size,
      };
      let data = {
        name_like: this.name_like,
        category_id: this.categoryActive,
      };
      getAppPage(pageData, data).then((res) => {
        let list = res.records;
        this.marketList = list.map((e) => {
          return {
            ...e,
            tagList: (e.tags && JSON.parse(e.tags)) || [],
          };
        });
        this.page.total = res.total;
        this.loading = false;
      });
    },
    // 分页
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadData();
    },
  },
};
</script>

<style scoped lang="scss">
.market-banner {
  height: 430px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  > .img {
    height: 430px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  > div {
    position: absolute;
    width: 1264px;
    left: 50%;
    top: 110px;
    transform: translate(-50%);
    z-index: 10;
    > :first-child {
      font-size: 38px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      line-height: 53px;
      margin-bottom: 24px;
      z-index: 10;
    }
    > :nth-child(2) {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 25px;
      margin-bottom: 56px;
      z-index: 10;
    }
    > .git-btn {
      width: 136px;
      height: 40px;
    }
  }
}

.market-search {
  &::v-deep {
    .ant-input {
      height: 48px;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.market-box {
  background: url("../../assets/img/market-bg.png") no-repeat;
  background-size: cover;
  padding: 70px 0 56px;
  .market-con {
    width: 1280px;
    margin: 0 auto;
    display: flex;

    > div:last-child {
      flex: 1;
      padding-left: 32px;
      .market-con-right {
        width: 100%;
        .market-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          > :not(:nth-child(2n)) {
            margin-right: 24px;
          }
          > div {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
  .market-page {
    margin-top: 12px;
    &::v-deep {
      text-align: right;
    }
  }
}

.market-con-tab {
  width: 234px;
  height: 630px;
  background: url(../../assets/img/market-tab.png) no-repeat;
  background-size: 100% 100%;
  > div {
    height: 50px;
    cursor: pointer;
    font-size: 16px;
    color: #262626;
    line-height: 50px;
    font-weight: bold;
    padding-left: 40px;
    &:hover {
      background: linear-gradient(90deg, #ea0b06 0%, rgba(234, 11, 6, 0) 100%);
      color: #ffffff;
    }
  }
  > .tab-active {
    background: linear-gradient(90deg, #ea0b06 0%, rgba(234, 11, 6, 0) 100%);
    color: #ffffff;
  }
}
</style>
