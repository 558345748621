<template>
  <div class="home-page">
    <a-pagination
      :total="total"
      :current="current"
      :default-page-size="size"
      show-quick-jumper
      :show-total="(total) => `共 ${total} 项`"
      @change="pageChange"
      @showSizeChange="showSizeChange"
    />
  </div>
</template>

<script>
export default {
  props:{
    total:{
      type:Number
    },
    current:{
      type:Number
    },
    size:{
      type:Number,
      default:10
    }
  },
  data() {
    return {
      page: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
    };
  },
  methods:{
    pageChange(page, pageSize){
        this.page.current = page
        this.page.pageSize = pageSize
        this.$emit('changes',this.page)
    },
    showSizeChange(current, size){
        this.page.pageSize = size
        this.page.current = current
        this.$emit('changes',this.page)
    }
  }
};
</script>

<style>
.home-page {
  text-align: center;
}
</style>