<template>
  <div class="marketCard" @click="handleClick(val)">
    <img v-if="val.photo" class="card-img" :src="val.photo" />
    <img v-else class="card-img" src="@/assets/img/kctp.png" />
    <div>
      <div class="title">{{ val.name }}</div>
      <p>{{ val.introduction }}</p>
      <div class="tag">
        <div class="tag-info" v-for="(el, i) in val.tagList" :key="i">{{
          el
        }}</div>
      </div>
      <div class="price">
        {{ val.price }}<span>{{ val.type }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "marketCard",
  props: ["val"],
  data() {
    return {
      tagList: [],
    };
  },
  methods: {
    handleClick(val) {
      const { app_source, id, sku_id } = val;
      window.open(
        `${
          process.env.VUE_APP_MARKET_APP_URI
        }/#/application-detail/${encodeURIComponent(
          JSON.stringify({
            curApp: {
              app_source: String(app_source),
              id: String(id),
              sku_id: String(sku_id),
            },
          })
        )}`
      );
    },
  },
};
</script>

<style scoped lang="scss">
.marketCard {
  width: 495px;
  height: 206px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(195, 195, 195, 0.4);
  padding: 32px 24px 32px 24px;
  display: flex;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 12px 0px rgba(195, 195, 195, 0.6);
    height: auto;
    .title {
      color: #ea0b06 !important;
    }
    .tag{

      max-height: none !important;
      overflow-y: auto;
    }
  }
  > .card-img {
    display: block;
    width: 80px;
    height: 80px;
  }
  > div:last-child {
    flex: 1;
    padding-left: 32px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
      margin-bottom: 15px;
      transition: all 0.3s;
    }
    > p {
      font-size: 12px;
      color: #666666;
      line-height: 17px;
      margin-bottom: 12px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    > .tag {
      margin-bottom: 24px;
      display: flex;
      flex-wrap: wrap;
      overflow-y: hidden;
      max-height: 18px;
      > div:not(:last-child) {
        margin-right: 8px;
      }
      .tag-work {
        background: #ea0b06;
        border-radius: 2px;
        padding: 0 4px;
        font-size: 12px;
        color: #ffffff;
        line-height: 18px;
      }
      .tag-info {
        font-size: 12px;
        color: #62697d;
        line-height: 16px;
        padding: 0 4px;
        border-radius: 2px;
        border: 1px solid #d1d8e4;
      }
    }
    > .price {
      font-size: 18px;
      font-weight: bold;
      color: #ea0b06;
      line-height: 25px;
      > span {
        font-size: 12px;
        font-weight: bold;
        color: rgba(140, 140, 140, 0.85);
        line-height: 17px;
      }
    }
  }
}
</style>
