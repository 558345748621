import { PortalRequest as request } from "@/utils/request";

export const getAppCategories = () => {
  return request({
    url: "/yuer/app-categories",
    method: "get",
  });
};
export const getAppList = (categoryId) => {
  return request({
    //TODO 暂时所有分类下都没数据，所以先固定categoryId为0
    url: `/yuer/app-list/0`,
    // url: `/yuer/app-list/${categoryId}`,
    method: "get",
  });
};

export const getAppPage = ({ current, size }, { category_id, name_like }) => {
  return request({
    url: `/yuer/app-page`,
    method: "post",
    params: { current, size },
    data: { category_id, name_like },
  });
};
